<template>
  <section class="entity-form-execute">
    <div
      v-loading="loading"
      v-loading.fullscreen.lock="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div v-if="getAllSteps && getAllSteps.length">
        <el-row>
          <el-col span="8">
            <h3 class="page-title d-flex align-center">
              <el-button
                size="mini"
                icon="el-icon-back"
                @click="backToWorkFlowUsersList()"
                >Back</el-button
              >
              <span class="ml-2" :style="getIsMobile ? 'margin-top: 5px' : '' ">{{ getFormBuilder.name }}</span>
            </h3>
          </el-col>
          <el-col span="8" offset="2">
            <div style="float: right; margin-top: 3px" v-if="approvalStatus">
              <div v-if="approvalStatus === 'APPROVED'">
                <el-popover
                  placement="top-start"
                  title="Approval details:"
                  :width="getIsMobile ? '350' : '400'"
                  trigger="hover"
                >
                  <div v-if="currentFormbuilderData.priority_based">
                    <span v-if="getApprovedUser && getApprovedUser.approved_by"
                      >Approved by: {{ getApprovedUser.approved_by }}</span
                    >
                    <br />
                    <span v-if="getApprovedUser && getApprovedUser.approved_at">
                      Approved at:
                      {{
                        convertToLocalDateTime(getApprovedUser.approved_at)
                          | globalDateFormat
                      }}
                    </span>
                  </div>
                  <div v-else>
                    <ul>
                      <li
                        v-for="approver in getApprovedUser"
                        :key="approver._id"
                        class="list-style"
                      >
                        <div
                          v-if="approver.approved_by && approver.approved_at"
                        >
                          <i class="el-icon-user-solid icon-style"></i>
                          Approved by:<span class="approver-text">{{
                            " " + approver.approved_by + ","
                          }}</span
                          ><br />
                          <i class="el-icon-time icon-style"></i>
                          Approved at:<span class="approver-text">{{
                            (" " + convertToLocalDateTime(approver.approved_at))
                              | globalDateTimeFormat
                          }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <h4 class="success" slot="reference" :style="getIsMobile ? 'margin-left:15px' : ''">
                    The status of this form is Approved
                    <i class="el-icon-info"></i>
                  </h4>
                </el-popover>
              </div>
              <div v-else-if="approvalStatus === 'REJECTED'">
                <el-popover
                  placement="top-start"
                  title="Rejection details"
                  width="300"
                  trigger="hover"
                >
                  <div>
                    <span v-if="getRejectedUser && getRejectedUser.rejected_by"
                      >Rejected by: {{ getRejectedUser.rejected_by }}</span
                    >
                    <br />
                    <span v-if="getRejectedUser && getRejectedUser.rejected_at">
                      Rejected at
                      {{
                        convertToLocalDateTime(getRejectedUser.rejected_at)
                          | globalDateFormat
                      }}
                    </span>
                    <br />
                    <span
                      v-if="getRejectedUser && getRejectedUser.rejected_reason"
                    >
                      Reason:
                      {{ getRejectedUser.rejected_reason }}
                    </span>
                  </div>
                  <h4 class="error" slot="reference">
                    The status of this form is Rejected
                    <i class="el-icon-info"></i>
                  </h4>
                </el-popover>
              </div>
              <div v-else class="d-flex">
                <h4 class="warning" slot="reference">
                  The status of this form is currently pending
                  <!-- <i class="el-icon-info"></i> -->
                </h4>
                <formbuilderLogs
                  class="warning"
                  :currentFormbuilderData="currentFormbuilderData"
                  :allEntitiesData="allEntitiesData"
                  :allCompanyUserTypesData="allCompanyUserTypesData"
                ></formbuilderLogs>
              </div>
            </div>
          </el-col>
        </el-row>
        <template v-if="getAllSteps && getAllSteps.length">
          <div style="display: flex !important" v-if="getIsMobile">
            <el-button
              :icon="toggleMenu ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
              class="mt-1"
              @click="displayToggle"
            ></el-button>
            <div class="list-group-menu" v-if="toggleMenu && getIsMobile">
              <el-menu class="el-menu-vertical-demo sidemenu">
                <template index="1" v-for="(step, index) of getAllSteps">
                  <el-submenu
                    :key="index"
                    :index="step._id"
                    v-if="step.type == 'ENTITY'"
                  >
                    <template slot="title">
                      <img
                        class="mr-1"
                        :src="
                          logoUploadUrl +
                          '/assets/img/template-icons/block-portfolio.svg'
                        "
                        alt="icons"
                        width="20"
                      />
                      <span slot="title">
                        {{
                          step.settings && step.settings.sender_title
                            ? step.settings.sender_title
                            : step.entity && step.entity.name
                            ? step.entity.name
                            : ""
                        }}
                      </span>
                    </template>
                    <el-menu-item-group>
                      <template v-for="(temp, i) in getEntityTemplates(step)">
                        <el-menu-item
                          :key="temp._id + i"
                          :index="temp._id"
                          @click="gotoEnitytTemplate(step, temp)"
                          :class="getParentClass(step, temp)"
                        >
                          <img
                            class="ml-2"
                            v-if="temp.icon"
                            :src="
                              logoUploadUrl +
                              '/assets/img/template-icons/' +
                              temp.icon
                            "
                            alt="icon"
                            width="20"
                          />
                          <img
                            v-else
                            class="ml-2"
                            :src="
                              logoUploadUrl +
                              '/assets/img/template-icons/block-portfolio.svg'
                            "
                            alt="icons"
                            width="20"
                          />
                          &nbsp;
                          <span class="menu-title">{{ temp.name }}</span>
                        </el-menu-item>
                      </template>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-menu-item
                    v-else-if="step.type == 'TEMPLATE'"
                    :key="index"
                  >
                    <router-link :class="getClass(step)" :to="getStepUrl(step)">
                      <img
                        class="ml-2"
                        v-if="step.templateInfo && step.templateInfo.icon"
                        :src="
                          logoUploadUrl +
                          '/assets/img/template-icons/' +
                          step.templateInfo.icon
                        "
                        alt="icon"
                        width="20"
                      />
                      <img
                        class="ml-2"
                        v-else
                        :src="
                          logoUploadUrl +
                          '/assets/img/template-icons/block-portfolio.svg'
                        "
                        alt="icons"
                        width="20"
                      />
                      {{
                        step.settings && step.settings.sender_title
                          ? step.settings.sender_title
                          : step.template && step.template.name
                          ? step.template.name
                          : step.entity && step.entity.name
                          ? step.entity.name
                          : ""
                      }}
                      <!-- <i :class="getCompletionStatusClass(step)"></i> -->
                    </router-link>
                  </el-menu-item>
                </template>
              </el-menu>
            </div>
          </div>
          <el-row v-if="!getIsMobile">
            <el-col :lg="5" :md="8" :sm="6" :xs="24">
              <div class="sidebar-navigation topwidth">
                <div class="list-group">
                  <el-menu class="el-menu-vertical-demo sidemenu">
                    <template index="1" v-for="(step, index) of getAllSteps">
                      <el-submenu
                        :key="index"
                        :index="step._id"
                        v-if="step.type == 'ENTITY'"
                        :class="{
                          'default-style':
                            !getCompanyDetails ||
                            !getCompanyDetails.styled_sidemenu,
                          'inverted-color-style':
                            getCompanyDetails &&
                            getCompanyDetails.styled_sidemenu,
                        }"
                      >
                        <template slot="title">
                          <img
                            class="mr-1"
                            :src="
                              logoUploadUrl +
                              '/assets/img/template-icons/block-portfolio.svg'
                            "
                            alt="icons"
                            width="20"
                          />
                          <span slot="title">
                            {{
                              step.settings && step.settings.sender_title
                                ? step.settings.sender_title
                                : step.entity && step.entity.name
                                ? step.entity.name
                                : ""
                            }}
                          </span>
                        </template>
                        <el-menu-item-group>
                          <template
                            v-for="(temp, i) in getEntityTemplates(step)"
                          >
                            <el-menu-item
                              :key="temp._id + i"
                              :index="temp._id"
                              @click="gotoEnitytTemplate(step, temp)"
                              :class="getParentClass(step, temp)"
                            >
                              <img
                                class="ml-2"
                                v-if="temp.icon"
                                :src="
                                  logoUploadUrl +
                                  '/assets/img/template-icons/' +
                                  temp.icon
                                "
                                alt="icon"
                                width="20"
                              />
                              <img
                                v-else
                                class="ml-2"
                                :src="
                                  logoUploadUrl +
                                  '/assets/img/template-icons/block-portfolio.svg'
                                "
                                alt="icons"
                                width="20"
                              />
                              &nbsp;
                              <span class="menu-title">{{ temp.name }}</span>
                            </el-menu-item>
                          </template>
                        </el-menu-item-group>
                      </el-submenu>
                      <el-menu-item
                        v-else-if="step.type == 'TEMPLATE'"
                        :key="index"
                      >
                        <router-link
                          :class="getClass(step)"
                          :to="getStepUrl(step)"
                        >
                          <img
                            class="ml-2"
                            v-if="step.templateInfo && step.templateInfo.icon"
                            :src="
                              logoUploadUrl +
                              '/assets/img/template-icons/' +
                              step.templateInfo.icon
                            "
                            alt="icon"
                            width="20"
                          />
                          <img
                            class="ml-2"
                            v-else
                            :src="
                              logoUploadUrl +
                              '/assets/img/template-icons/block-portfolio.svg'
                            "
                            alt="icons"
                            width="20"
                          />
                          {{
                            step.settings && step.settings.sender_title
                              ? step.settings.sender_title
                              : step.template && step.template.name
                              ? step.template.name
                              : step.entity && step.entity.name
                              ? step.entity.name
                              : ""
                          }}
                          <!-- <i :class="getCompletionStatusClass(step)"></i> -->
                        </router-link>
                      </el-menu-item>
                    </template>
                  </el-menu>
                </div>
              </div>
            </el-col>
            <el-col :lg="19" :md="16" :sm="18" :xs="24">
              <div v-if="dialogVisible">
                <dialog-component
                  :visible="dialogVisible"
                  :title="getSingleTemplateTitle"
                  :fullscreen="true"
                  @before-close="backToWorkFlowUsersList"
                  :width="getIsMobile ? '100%' : '100%'"
                  class="el-dialog el-dialog-fullscreen"
                  :containerMaxHeight="'90vh'"
                  :containerMaxWidth="'90vw'"
                  :containerWidth="'80vw'"
                  :isShowFooter="false"
                >
                  <div
                    style="margin-left: 8px; margin-top: 10px"
                    v-if="approvalStatus"
                  >
                    <div v-if="approvalStatus === 'APPROVED'">
                      <el-popover
                        placement="top-start"
                        title="Approval details:"
                        width="300"
                        trigger="hover"
                      >
                        <div v-if="currentFormbuilderData.priority_based">
                          <span
                            v-if="
                              getApprovedUser && getApprovedUser.approved_by
                            "
                            >Approved by:
                            {{ getApprovedUser.approved_by }}</span
                          >
                          <br />
                          <span
                            v-if="
                              getApprovedUser && getApprovedUser.approved_at
                            "
                          >
                            Approved at:
                            {{
                              convertToLocalDateTime(
                                getApprovedUser.approved_at
                              ) | globalDateFormat
                            }}
                          </span>
                        </div>
                        <div v-else>
                          <ul>
                            <li
                              v-for="approver in getApprovedUser"
                              :key="approver._id"
                              class="list-style"
                            >
                              <div
                                v-if="
                                  approver.approved_by && approver.approved_at
                                "
                              >
                                <i class="el-icon-user-solid icon-style"></i>
                                Approved by:<span class="approver-text">{{
                                  " " + approver.approved_by + ","
                                }}</span
                                ><br />
                                <i class="el-icon-time icon-style"></i>
                                Approved at:<span class="approver-text">{{
                                  (" " +
                                    convertToLocalDateTime(
                                      approver.approved_at
                                    ))
                                    | globalDateTimeFormat
                                }}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <h4 class="success" slot="reference">
                          The status of this form is Approved
                          <i class="el-icon-info"></i>
                        </h4>
                      </el-popover>
                    </div>
                    <div v-else-if="approvalStatus === 'REJECTED'">
                      <el-popover
                        placement="top-start"
                        title="Rejection details"
                        width="300"
                        trigger="hover"
                      >
                        <div>
                          <span
                            v-if="
                              getRejectedUser && getRejectedUser.rejected_by
                            "
                            >Rejected by:
                            {{ getRejectedUser.rejected_by }}</span
                          >
                          <br />
                          <span
                            v-if="
                              getRejectedUser && getRejectedUser.rejected_at
                            "
                          >
                            Rejected at
                            {{ getRejectedUser.rejected_at | globalDateFormat }}
                          </span>
                          <br />
                          <span
                            v-if="
                              getRejectedUser && getRejectedUser.rejected_reason
                            "
                          >
                            Reason:
                            {{ getRejectedUser.rejected_reason }}
                          </span>
                        </div>
                        <h4 class="error" slot="reference">
                          The status of this form is Rejected
                          <i class="el-icon-info"></i>
                        </h4>
                      </el-popover>
                    </div>
                    <div v-else class="d-flex">
                      <h4 class="warning" slot="reference">
                        The status of this form is currently pending
                        <!-- <i class="el-icon-info"></i> -->
                      </h4>
                      <formbuilderLogs
                        class="warning"
                        :currentFormbuilderData="currentFormbuilderData"
                        :allEntitiesData="allEntitiesData"
                        :allCompanyUserTypesData="allCompanyUserTypesData"
                      ></formbuilderLogs>
                    </div>
                  </div>
                  <router-view
                    :layout.sync="layout"
                    :key="$route.fullPath"
                  ></router-view>
                </dialog-component>
              </div>
              <div v-else class="main-content-block">
                <router-view
                  :layout.sync="layout"
                  :key="$route.fullPath"
                ></router-view>
              </div>
            </el-col>
          </el-row>
          <el-row v-else-if="getIsMobile">
            <el-col :lg="24" :md="24" :sm="24" :xs="24">
              <div class="main-content-block">
                <router-view
                  :layout.sync="layout"
                  :key="$route.fullPath"
                ></router-view>
              </div>
            </el-col>
          </el-row>
        </template>
      </div>
    </div>
    <el-dialog
      class="send-user-documents"
      title="Form builder sent successfully"
      :visible.sync="userDetailsDialogVisible"
      top="20px"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="backToWorkFlowUsersList"
    >
      <template v-if="showSharedURL">
        <el-row>
          <el-col :span="3">Email:</el-col>
          <el-col :span="12">
            <b>{{ showSharedURL.email }}</b>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="mt-1">
          <el-col :span="3">Link:</el-col>
          <el-col :span="12">
            <el-input
              v-model="showSharedURL.formbuilderUrl"
              readonly
              size="mini"
            ></el-input>
          </el-col>
          <el-col :span="6">
            <el-button
              size="mini"
              icon="el-icon-copy-document"
              class="ml-1"
              @click="copyURL(showSharedURL.formbuilderUrl)"
              >Copy</el-button
            >
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="backToWorkFlowUsersList" type="primary" size="mini"
            >Done</el-button
          >
        </span>
      </template>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
export default {
  components: {
    formbuilderLogs: () => import("@/components/widgets/formbuilderLogsWidget"),
  },
  data() {
    return {
      loading: false,
      step: 1,
      slug: null,
      layout: "div",
      employeeData: {},
      stepTypeMap: {
        COMPANY_BENEFIT: "Company Benefits",
        COMPANY_DOCUMENTS: "Company Documents",
        OFFER_LETTER: "Offer Letter",
        RELATIONAL_DATA: "Relational Data",
      },
      stepTypeIconMap: {
        COMPANY_BENEFIT: "document-folder.svg",
        COMPANY_DOCUMENTS: "document.svg",
        OFFER_LETTER: "employee-CV.svg",
        RELATIONAL_DATA: "employee-CV.svg",
      },
      formbuilderCode: null,
      formbuilderDataId: null,
      userDetailsDialogVisible: false,
      userDetails: {
        email: "",
        first_name: "",
        last_name: "",
      },
      workflowSendingStepsData: [],
      workflowSendingUserData: {},

      workflowSendingDocumentsData: [],
      showSharedURL: "",
      loadingText: "Loading",
      toggleMenu: false,
      approvalStatus: "",
      currentFormbuilderData: null,
      dialogVisible: false,
      allEntitiesData: [],
      allCompanyUserTypesData: [],
    };
  },

  mixins: [FormbuilderHelper, TemplateBuilderHelper],
  async mounted() {
    [this.allCompanyUserTypesData, this.allEntitiesData] = await Promise.all([
      this.fetchCompanyUsersTypes(),
      this.fetchAllEntities(),
    ]);
    this.getNecessaryInfo();
  },

  created() {
    // bus.$on("prev-step", () => {
    //   this.gotoPrevStep();
    // });
    bus.$on("next-step", (data) => {
      // let prevData = this.getPreviewData;
      // let finalData = { ...prevData, ...data };
      // this.$store.dispatch("formBuilders/updatePreviewData", finalData, {
      //   root: true
      // });

      if (
        data &&
        data.getNewFormbuilderData &&
        data.getNewFormbuilderData._id
      ) {
        this.formbuilderDataId = data.getNewFormbuilderData._id;
      }

      if (data && data.finish) {
        this.backToWorkFlowUsersList();
      }

      this.gotoNextStep();
    });
    bus.$on("send-formbuilder", () => {
      this.sendFormbuilder();
    });
  },
  beforeDestroy() {
    bus.$off("next-step");
  },
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getPreviewData",
      "getFormBuilderPrevRouter",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    ...mapGetters("formbuilderData", [
      "getUserFormbuilderDataWithTemplate",
      "getFormBuilderDataAssetDataAddStatus",
      "getUserFormbuilderData",
      "getFormbuilderSentStatus",
      "getFormbuilderSentData",
      "getFormbuilderSentErrors",
    ]),
    ...mapGetters("employee", ["getSingleEmployeeData"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("auth", ["getUserTypeList"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    getIndexFromStep() {
      return this.step - 1;
    },
    getStepDetails() {
      if (
        this.getFormBuilder &&
        this.getFormBuilder.steps &&
        this.getFormBuilder.steps.length
      ) {
        return this.getFormBuilder.steps[this.getIndexFromStep];
      }
      return {};
    },
    getAllSteps() {
      if (this.getFormBuilder && this.getFormBuilder.steps) {
        return this.getFormBuilder.steps;
      }
      return [];
    },
    getSingleTemplateTitle() {
      if (
        this.getAllSteps &&
        this.getAllSteps.length === 1 &&
        this.getAllSteps[0]?.type == "TEMPLATE" &&
        this.getAllSteps[0]?.template &&
        this.getAllSteps[0]?.template?.name
      ) {
        return this.getAllSteps[0].template.name;
      } else if (
        this.getAllSteps.length === 1 &&
        this.getAllSteps[0]?.type == "ENTITY"
      ) {
        return this.getAllSteps[0]?.entity?.name;
      } else {
        return "Dialog";
      }
    },
    isEdit() {
      return (
        this.$route.name == "FormbuilderEditStep" ||
        this.$route.name == "FormbuilderEdit" ||
        this.$route.name == "FormbuilderEditStepSSO_External" ||
        this.$route.name == "FormbuilderEditSSO_External"
      );
    },
    isApplicationUser() {
      if (
        [
          "ApplicationUserFormbuilderView",
          "ApplicationUserFormbuilderViewStep",
          "ApplicationUserFormbuilderEdit",
          "ApplicationUserFormbuilderEditStep",
        ].indexOf(this.$route.name) !== -1
      ) {
        return true;
      }
      return false;
    },
    getStepUrl() {
      return (step) => {
        let url = "";
        if (this.isApplicationUser) {
          url = "/ap";
        }
        if (this.getIframeUrlInfo != null) {
          url = `/if/${this.getIframeUrlInfo._id}`;
        }
        url = url + "/fb/";
        if (this.formbuilderDataId) {
          url =
            url +
            `${this.isEdit ? "edit" : "view"}/${this.formbuilderCode}/${
              step.slug
            }/${this.formbuilderDataId}`;
        } else {
          url =
            url +
            `${this.isEdit ? "edit" : "view"}/${this.formbuilderCode}/${
              step.slug
            }`;
        }
        if (step.type == "ENTITY") {
          let index = this.$router?.query?.temp_id;
          if (!index && index != 0 && index != "0") {
            index = 0;
          }
          url =
            url +
            `?step_id=${step._id}&temp_id=${index}&dialogVisible=${this.dialogVisible}`;
          if (this.$route.query?.origin) {
            url = url + "&origin=" + this.$route.query?.origin;
          }
          if (
            this.$router.query &&
            JSON.stringify(this.$router.query).includes("?")
          ) {
            url = url + "&up=" + this.$route.query?.up || true;
          } else {
            url = url + "?up=" + this.$route.query?.up || true;
          }
        } else {
          if (this.$route.query?.origin) {
            url = url + "?origin=" + this.$route.query?.origin;
          }
          if (
            this.$router.query &&
            JSON.stringify(this.$router.query).includes("?")
          ) {
            url = url + "&up=" + this.$route.query?.up || true;
          } else {
            url = url + "?up=" + this.$route.query?.up || true;
          }
        }
        return url;
      };
    },
    activeStep() {
      return (step) => {
        if (this.$route.params.template_code === step.slug) {
          return true;
        }
        return false;
      };
    },

    isCompleted() {
      return this.getUserFormbuilderData.workflow_data_status == "COMPLETED";
    },
    getCompletionStatusClass() {
      return (step) => {
        if (this.getUserFormbuilderData) {
          let workflowStepsData =
            this.getUserFormbuilderData.workflow_steps_data || [];
          let stepData = workflowStepsData.find((x) => x.step_id == step._id);
          if (stepData && stepData.completion_status) {
            return `el-icon-success step-success`;
          } else {
            return `el-icon-circle-check step-default`;
          }
        }
        return `el-icon-circle-check step-default`;
      };
    },
    isDocumentsSending() {
      let data = this.workflowSendingStepsData.find(
        (x) => x.type == "COMPANY_DOCUMENTS" && x.sent_status
      );
      return data ? true : false;
    },
    getParentClass() {
      return (step, temp) => {
        let selectedTemplates = [];
        if (this.getFormBuilder?.steps) {
          let steps = this.getFormBuilder?.steps || [];
          selectedTemplates = steps.flatMap(
            (step) => step?.settings?.selectedTemplates || []
          );
        }
        let index = (selectedTemplates || []).findIndex(
          (e) => e == temp?.template_id?._id
        );
        // let index = (step?.entity?.templates || []).findIndex(e => {
        //   if (e?.template_id?._id == temp?.template_id?._id) {
        //     return e;
        //   }
        // });
        if (
          // this.$route.params.template_code == step.slug &&
          index > -1 &&
          index == this.$route.query?.temp_id
        ) {
          return "list-group-item list-group-item-action active";
        } else {
          return "list-group-item list-group-item-action";
        }
      };
    },
    getClass() {
      return (step) => {
        if (
          this.$route.params.template_code == step.relationship_slug ||
          (step.template &&
            this.$route.params.template_code == step.template.slug)
        ) {
          return "list-group-item list-group-item-action active";
        } else {
          return "list-group-item list-group-item-action";
        }
      };
    },
    // getChild
    getApprovedUser() {
      // In priority based approvals, only main approver details should be displayed
      if (
        this.currentFormbuilderData?.approval_users &&
        this.currentFormbuilderData?.priority_based
      ) {
        return this.currentFormbuilderData?.approval_users.find(
          (e) =>
            (!this.currentFormbuilderData.priority_based &&
              e.approved_status) ||
            (this.currentFormbuilderData.priority_based &&
              e.approved_status &&
              e.final_approver)
        );
      } else {
        // In non-priority based(all users need to approve) approvals, all approvers details should be displayed, so returning all the approvers
        return this.currentFormbuilderData?.approval_users;
      }
    },
    getRejectedUser() {
      if (this.currentFormbuilderData?.approval_users) {
        return this.currentFormbuilderData?.approval_users.find(
          (e) =>
            (!this.currentFormbuilderData.priority_based &&
              e.rejected_status) ||
            (this.currentFormbuilderData.priority_based &&
              e.rejected_status &&
              e.final_approver)
        );
      }
      return null;
    },
  },
  methods: {
    convertToLocalDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString();
    },
    displayToggle() {
      this.toggleMenu = !this.toggleMenu;
    },
    gotoEnitytTemplate(step, temp) {
      let selectedTemplates = [];
      if (this.getFormBuilder?.steps) {
        let steps = this.getFormBuilder?.steps || [];
        selectedTemplates = steps.flatMap(
          (step) => step?.settings?.selectedTemplates || []
        );
      }
      let index = (selectedTemplates || []).findIndex(
        (e) => e == temp?.template_id?._id
      );
      if (index == -1) {
        index = 0;
      }
      if (
        this.$route?.query?.temp_id &&
        parseInt(this.$route.query.temp_id) == index
      ) {
        return;
      }
      let url = this.getStepUrl(step);
      url = url.replace("temp_id=0", `temp_id=${index}`);
      this.$router.push({ path: url });
    },
    getEntityTemplates(step) {
      let selectedTemplates = [];
      if (this.getFormBuilder?.steps) {
        let steps = this.getFormBuilder?.steps || [];
        selectedTemplates = steps.flatMap(
          (step) => step?.settings?.selectedTemplates || []
        );
      }
      let templates = (step?.entity?.templates || []).filter((template) =>
        selectedTemplates.includes(template?.template_id?._id)
      );
      if (templates) {
        return templates.map((e) => {
          e.name = e?.template_id?.name;
          e._id = e?.template_id?._id;
          e.icon = e?.template_id?.icon;
          return e;
        });
      }
      return [];
    },
    Print(step) {
      console.log("ST : ", step);
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.formbuilderDataId = this.$route.params.formbuilder_data_id;
      this.step = this.$route.query.step || 1;
      this.formbuilderCode = this.$route.params.formbuilder_code;

      await this.$store.dispatch("formBuilders/fetchFormBuilderByCode", {
        code: this.formbuilderCode,
        include_template_details: true,
        include_entity_details: true,
        include_documents_details: true,
      });
      if (
        this.getAllSteps &&
        this.getAllSteps.length === 1 &&
        ((this.getAllSteps[0]?.type === "TEMPLATE" &&
          !this.getAllSteps[0]?.template?.is_repeatable) ||
          (this.getAllSteps[0]?.entity?.entity_type === "GENERAL" &&
            this.getAllSteps[0]?.entity?.templates.length === 1))
      ) {
        this.tempName =
          this.getAllSteps && this.getAllSteps[0]?.entity?.templates[0]?.name;
        this.dialogVisible = true;
      }
      if (!this.$route.params.template_code) {
        this.gotoStep(this.getAllSteps[0]);
      }

      if (!this.formbuilderDataId) {
        this.$store.dispatch("formbuilderData/clearUserFormbuilderData");
      } else {
        await this.$store.dispatch("formbuilderData/fetchUserFormbuilderData", {
          id: this.formbuilderDataId,
          include_contacts_data: true,
        });
      }
      this.currentFormbuilderData = this.getUserFormbuilderData;
      this.approvalStatus = this.currentFormbuilderData.approval_status;

      this.loading = false;
    },
    gotoStep(step) {
      this.$router.push({ path: this.getStepUrl(step) });
    },
    gotoPrevStep() {
      const code = this.$route.params.template_code;
      const currentStepIndex = this.getAllSteps.findIndex(
        (x) => x.slug == code
      );
      if (currentStepIndex > 0 && currentStepIndex < this.getAllSteps.length) {
        this.gotoStep(this.getAllSteps[currentStepIndex - 1]);
      }
    },
    gotoNextStep() {
      const code = this.$route.params.template_code;
      const currentStepIndex = this.getAllSteps.findIndex(
        (x) => x.slug == code
      );
      if (
        currentStepIndex > -1 &&
        currentStepIndex < this.getAllSteps.length - 1
      ) {
        this.gotoStep(this.getAllSteps[currentStepIndex + 1]);
      }
    },
    backToWorkFlowUsersList() {
      if (
        [
          "ApplicationUserFormbuilderEditStep",
          "ApplicationUserFormbuilderViewStep",
          "ApplicationUserFormbuilderView",
          "ApplicationUserFormbuilderEdit",
        ].indexOf(this.$route.name) !== -1
      ) {
        if (this.getFormBuilderPrevRouter) {
          location.href = this.getFormBuilderPrevRouter;
          this.$store.commit("formBuilders/setFormBuilderPrevRouter", null, {
            root: true,
          });
        } else {
          this.$router.go(-2);
        }
      } else if (
        this.$route?.query?.origin == "ckjwdiuv" ||
        this.$route?.query?.origin?.includes("ckjwdiuv")
      ) {
        this.$router.push({
          path: "/forms/approval-forms",
        });
      } else if (
        this.$route?.query?.origin == "cwdjkrni" ||
        this.$route?.query?.origin?.includes("cwdjkrni")
      ) {
        this.$router.push({
          path: "/ap/approvalforms",
        });
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/fbd/`;
        } else {
          UrlPathAdd = "/fbd/";
        }
        this.$router.push({
          path: UrlPathAdd + this.formbuilderCode,
        });
      }
    },
    async confirmUserDetails() {
      await this.$store.dispatch("formbuilderData/fetchUserFormbuilderData", {
        id: this.formbuilderDataId,
      });
      this.$store.dispatch("formbuilderData/clearWorkflowErrors");

      // let data = this.getUserFormbuilderData.workflow_steps_data;

      let data = this.getAllSteps;

      let stepsData = [];
      data.forEach((step) => {
        let stepdata = {
          step_name:
            step.type == "TEMPLATE"
              ? step.template.name
              : this.stepTypeMap[step.type],
          sent_status:
            step.sent_status ||
            (step.type == "TEMPLATE" ? step.template.is_public : true),
          step_id: step._id,
          type: step.type,
          is_public: step.type == "TEMPLATE" ? step.template.is_public : true,
        };
        stepsData.push(stepdata);
      });

      this.workflowSendingStepsData = stepsData;

      await this.$store.dispatch("employee/fetchEmployeeSingleEmployee", {
        id: this.getUserFormbuilderData.user_id,
      });

      this.workflowSendingUserData = this.getSingleEmployeeData;
      this.userDetailsDialogVisible = true;
    },
    async sendFormbuilder() {
      try {
        this.loading = true;
        let params = {
          formbuilder_data_id: this.formbuilderDataId,
          contact: this.getUserFormbuilderData.contact,
        };
        await this.$store.dispatch(
          "formbuilderData/sendFormbuilderDataToUser",
          params
        );

        if (this.getFormbuilderSentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Form builder sent to user successfully",
          });
          this.loading = false;
          this.showSharedURL = this.getFormbuilderSentData;
          this.userDetailsDialogVisible = true;
        } else {
          this.loading = false;
          let message =
            this.getFormbuilderSentErrors &&
            this.getFormbuilderSentErrors.critical_error
              ? this.getFormbuilderSentErrors.critical_error
              : this.getFormbuilderSentErrors;
          this.$notify.error({
            title: "Error",
            message: message ? message : "Error at sending workflow to User",
          });
        }
      } catch (err) {
        this.loading = false;
        console.log("sendFormbuilder", err);
      }
    },

    async closeSendWorkflowDialog() {
      this.sendWorkflow;
    },
    async setI9FormAuthorizedUser(data) {
      this.i9formAuthorizedUserId = data._id;
    },

    copyURL(url) {
      navigator.clipboard.writeText(url);
      this.$message({
        message: "Link copied successfully!",
        type: "success",
      });
    },
  },
  watch: {
    "$route.query.step"(step) {
      this.step = step;
    },
    "$route.params.slug"(slug) {
      this.slug = slug;
    },
  },
};
</script>

<style lang="scss" scoped>
.workflow-form-execute {
  // margin-top: -45px;
  // margin-right: -30px;
  .main-content-block {
    // padding: 2em;
    background-color: #ffffff;

    // ::v-deep .preview-template-view {
    //   .preview-form-header {
    //     margin-bottom: 1.5em !important;
    //   }
    //   .footer-action-block {
    //     .el-button {
    //       margin: 0em 0 0 auto;
    //     }
    //   }
    //   .form-group {
    //     label {
    //       color: #5e6d82 !important;
    //     }
    //     p {
    //       margin-top: 0px;
    //     }
    //   }
    // }
  }
  .sidebar-navigation {
    position: sticky;
    top: 100px;
    width: auto;
    .page-title {
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-bottom: 1em;
      .scrolling-text {
        position: relative;
        width: 240px;
        min-height: 25px;
        overflow: hidden;
        line-height: 1.6;
        padding: 0px;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 20px;
          background-image: linear-gradient(
            to right,
            #f8f9fa00,
            #f8f9fa05,
            #f8f9fa
          );
        }
        span {
          position: absolute;
          white-space: nowrap;
          transform: translateX(0);
          transition: 1.5s;
        }
        &:hover span {
          transform: translateX(calc(250px - 110%));
        }
      }
      .el-button {
        margin-right: 0.5em;
        padding: 4px;
        i {
          font-size: 1.1em;
        }
      }
    }
    .templates-list {
      margin-top: 2em;
      max-height: calc(100vh - 220px);
      overflow-y: scroll;
      .nav-link {
        a {
          position: relative;
          color: #999a9d;
          display: flex;
          align-items: center;
          padding: 1em 1em 1em 1.5em;
          margin-bottom: 3px;
          border-radius: 3px 0 0 3px;
          & * {
            vertical-align: middle;
          }
          img {
            margin-right: 5px;
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            width: 10px;
            background-image: url("../../assets/img/icons/hover-dots.svg");
            background-size: 7px;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition: 0.3s all ease-in-out;
            -webkit-transition: 0.3s all ease-in-out;
          }
          &:hover:after {
            opacity: 1;
          }
          &:hover {
            color: #1b487e;
          }
          .step-edit,
          .step-unfill,
          .step-default,
          .step-success {
            position: absolute;
            top: 19px;
            right: 15px;
            font-size: 1.25em;
            padding-top: 3px;
          }
          .step-success {
            color: rgb(103, 206, 0);
          }

          .step-unfill {
            color: rgb(214, 214, 52);
          }

          .step-edit {
            color: blue;
          }

          .step-default {
            color: #999999;
          }
        }
        .router-link-active {
          color: #1b487e;
          background-color: #ffffff;
          box-shadow: -5px 5px 10px #f9f9f9;
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.list-of-steps {
  .el-checkbox__label {
    color: #5e6d82 !important;
  }
}
.send-user-documents {
  .el-dialog__body {
    max-height: calc(100vh - 200px);
  }
  .documents-list {
    margin: 0.35em;
    max-width: 300px;
    .document {
      display: flex;
      align-items: center;
      padding: 2px 0px;
      justify-content: space-between;
      width: 100%;
      i {
        color: rgb(255, 160, 92);
      }
      &.unfill {
        i {
          color: #ff5555;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.workflow-form-execute {
  margin-top: 0px !important;
  margin-right: 0px !important;
  .main-content-block {
    padding: 2em;
    background-color: #ffffff;

    ::v-deep .preview-template-view {
      .preview-form-header {
        margin-bottom: 1.5em !important;
      }
      .footer-action-block {
        .el-button {
          margin: 0em 0 0 auto;
        }
      }
      .form-group {
        label {
          color: #5e6d82 !important;
        }
        p {
          margin-top: 0px;
        }
      }
    }
  }
  .sidebar-navigation {
    position: sticky;
    top: 100px;
    .page-title {
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-bottom: 1em;
      .scrolling-text {
        position: relative;
        width: 240px;
        min-height: 25px;
        overflow: hidden;
        line-height: 1.6;
        padding: 0px;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 20px;
          background-image: linear-gradient(
            to right,
            #f8f9fa00,
            #f8f9fa05,
            #f8f9fa
          );
        }
        span {
          position: absolute;
          white-space: nowrap;
          transform: translateX(0);
          transition: 1.5s;
        }
        &:hover span {
          transform: translateX(calc(250px - 110%));
        }
      }
      .el-button {
        margin-right: 0.5em;
        padding: 4px;
        i {
          font-size: 1.1em;
        }
      }
    }
    .templates-list {
      margin-top: 2em;
      .nav-link {
        a {
          position: relative;
          color: #999a9d;
          display: block;
          padding: 1em 1em 1em 2em;
          margin-bottom: 0.5em;
          border-radius: 3px 0 0 3px;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            width: 10px;
            background-image: url("../../assets/img/icons/hover-dots.svg");
            background-size: 7px;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition: 0.3s all ease-in-out;
            -webkit-transition: 0.3s all ease-in-out;
          }
          &:hover:after {
            opacity: 1;
          }
          &:hover {
            color: #1b487e;
          }
          .step-edit,
          .step-unfill,
          .step-default,
          .step-success {
            float: right;
            font-size: 1.25em;
            padding-top: 3px;
          }
          .step-success {
            color: rgb(103, 206, 0);
          }

          .step-unfill {
            color: rgb(214, 214, 52);
          }

          .step-edit {
            color: blue;
          }

          .step-default {
            color: #999999;
          }
        }
        .router-link-active {
          color: #1b487e;
          background-color: #ffffff;
          box-shadow: -5px 5px 10px #f9f9f9;
          &::after {
            opacity: 1;
          }

          .step-success {
            color: rgb(103, 206, 0);
          }
        }
      }
    }
  }
}
.statstyle {
  margin-top: 28px;
  margin-left: 20px;
  border-radius: 10px;
  width: 180px;
  height: 65px;
}
.resStyle {
  margin-top: 19px;
  margin-left: 20px;
  border-radius: 10px;
  width: 130px;
  height: 55px;
}
.sidemenu {
  height: 520px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.topwidth {
  margin-top: 50px;
}
.list-group {
  margin-top: 15px;
  width: 270px;
  border-bottom: 1px ridge;
  .list-group-item {
    margin-right: -20px;
    margin-left: -20px;
    padding: 0em;
    border: none;
    // color: #9299b8;
    // // &.active {
    //   color: #f754a2;
    //   border-left: 3px solid #f754a2;
    // }
  }
}

@media screen and (min-width: 768px) {
  .list-group {
    margin-top: 15px;
    width: 240px;
    border-bottom: 1px ridge;
  }

  .list-group .list-group-item {
    margin-right: -20px;
    margin-left: -20px;
    padding: 0em;
    border: none;
    // color: #9299b8;
  }

  // .list-group .list-group-item.active {
  //   color: #f754a2;
  //   border-left: 3px solid #f754a2;
  // }
}

.send-user-documents {
  .el-dialog__body {
    max-height: calc(100vh - 200px);
  }
  .documents-list {
    margin: 0.35em;
    max-width: 300px;
    .document {
      display: flex;
      align-items: center;
      padding: 2px 0px;
      justify-content: space-between;
      width: 100%;
      i {
        color: rgb(255, 160, 92);
      }
      &.unfill {
        i {
          color: #ff5555;
        }
      }
    }
  }
}
.el-table .cell {
  word-break: break-word;
}
.el-table .td {
  word-wrap: break-word;
}
.list-group-menu {
  // margin-top: 35px;
  // border-bottom: 1px ridge;
  position: absolute;
  margin-top: 50px;
  z-index: 99;
  width: 150px;
  .list-group-item {
    margin-right: -20px;
    margin-left: -20px;
    padding: 0em;
    border: none;
    // color: #fdfdfd;
    // &.active {
    //   color: #f754a2;
    //   border-left: 3px solid #f754a2;
    // }
  }
}
.approver-text {
  color: #3366cc;
}
.list-style {
  margin-top: 10px;
}

// .list-group .list-group-item:hover {
//     margin-right: -20px;
//     margin-left: -20px;
//     padding: 0em;
//     border: none;
//     color: #9299b8;
//     background-color: #ecf5ff;
//     /* Add any additional styles for hover state here */
// }

@media (max-width:767px){
  .el-col-8 {
      width: 97% !important;
      position: relative;
      right: 20px;
  }
}
</style>
